<div class="main-body-con">
  <div class="page-container pt-4">
    <div class="page-hdr-outer ps-4 pe-4">
      <div class="page-hdr">
        <h2>User Management</h2>
        <div class="flex-div">
          <a href="javascript:void(0)" (click)="openAddUserRoleModal()" title="">
            <span><img src="assets/images/plus.svg" alt="" /></span><label class="ps-2">Add User</label></a>
          <a href="javascript:void(0)" (click)="clearCache()" title="" class="ps-4">
            <span><img src="assets/images/cache.png" alt="" width="10px" /></span>
          </a>
        </div>
      </div>
      <div class="grid-con mx-4 ">
        <div class="grid__wrapper wrp-pros custom-height1">
          <kendo-grid [resizable]="true" filterable="menu" (filterChange)="gridFilterChange($event)" [virtualColumns]="true"
            [data]="finalUserList" [sortable]="true" [sort]="sort" (sortChange)="sortChange($event)" [skip]="skip"
            [filter]="gridFilters" [pageable]="{ pageSizes: true, position: 'top' }" [pageSize]="pageSize"
            (pageChange)="paginationChange($event)" [sortable]="{
              allowUnsort: false,
              mode: multiple ? 'multiple' : 'single'
            }">
            <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
              <div class="flex-div justify-content-between grid-hd">
                <div class="flex-div">
                  <kendo-textbox placeholder="Search in all columns..." #searchText
                    (input)="searchValue(searchText.value)" [style.width.px]="205"></kendo-textbox><img
                    src="assets/images/search.svg" (click)="onFilter()" alt="" class="ms-2" />
                </div>
                <div class="flex-div">
                  <div class="flex-div">
                    <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
                    <kendo-pager-numeric-buttons [buttonCount]="buttonCount"></kendo-pager-numeric-buttons>
                    <kendo-pager-next-buttons></kendo-pager-next-buttons>
                    <kendo-pager-page-sizes [pageSizes]="sizes"></kendo-pager-page-sizes>
                    <kendo-grid-messages pagerItems="Items" [pagerItemsPerPage]="'Items per page'">
                    </kendo-grid-messages>
                  </div>
                  <kendo-pager-info class="bd-l"></kendo-pager-info>
                </div>
              </div>
              <!-- <button [ngClass]="{ actbtn: isFilter }" (click)="columnFilter(isFilter)" class="filter-button">
                <img src="assets/images/filter.svg" alt="" class="flt-icon" />
              </button> -->
            </ng-template>
            <kendo-grid-column [minResizableWidth]="80" [width]="20" field="Actions" [filterable]="false" class="overflow-visible"
              [sortable]="false">
              <ng-template kendoGridCellTemplate let-rowData>
                <a href="javascript:void(0)" title="" class="tbl-act pointer list-action" id="dropdownMenuButton1"
                  data-bs-toggle="dropdown" aria-expanded="false"><img src="assets/images/action.svg" alt="" /></a>
                <ul class="dropdown-menu action-dd act-pos" aria-labelledby="dropdownMenuButton1">
                  <li>
                    <a (click)="openEditUserRoleModal(rowData)" class="dropdown-item" href="javascript:void(0)"><img
                        src="../../assets/images/edit.svg" alt="Edit Recall" />
                      <span>Edit User Profile</span></a>
                  </li>
                </ul>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column [minResizableWidth]="150" [maxResizableWidth]="500" field="display_name"
              title="Display Name" [width]="40" [filterable]="true">
              <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
                <kendo-grid-string-filter-menu [column]="column" [filter]="filter" [filterService]="filterService"
                  operator="startswith">
                </kendo-grid-string-filter-menu>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column [minResizableWidth]="150" field="roleName" title="Role" [width]="50" [filterable]="true">
              <ng-template kendoGridFilterMenuTemplate let-filter>
                <kendo-multiselect [checkboxes]="true" [(ngModel)]="gridFilterArrayRoleName" [autoClose]="true"
                  [data]="rolesList" [valuePrimitive]="true" (valueChange)="onRoleNameChange($event)"
                  textField="roleName" valueField="roleName">
                </kendo-multiselect>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column [minResizableWidth]="150" [maxResizableWidth]="500" field="position" title="Position"
              [width]="40" [filterable]="true">
            </kendo-grid-column>
            <kendo-grid-column [minResizableWidth]="150" [maxResizableWidth]="500" field="email" title="Email"
              [width]="40" [filterable]="true">
            </kendo-grid-column>
            <kendo-grid-column [minResizableWidth]="200" [maxResizableWidth]="500" field="userName" title="User Name"
              [width]="40" [filterable]="true">
              <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
                <kendo-grid-string-filter-menu [column]="column" [filter]="filter" [filterService]="filterService"
                  operator="startswith">
                </kendo-grid-string-filter-menu>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="phoneNumber" title="Phone Number" [width]="35" [filterable]="true">
              <ng-template kendoGridCellTemplate let-dataItem>
                {{dataItem.phoneNumber ? (dataItem.phoneNumber | mask: "(000) 000-0000") : " "}}
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column [minResizableWidth]="180" [maxResizableWidth]="500" field="extension" title="Ext"
              [width]="30" [filterable]="true" class="text-right">
              <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
                <number-range-filter [field]="column.field" [filter]="filter" [filterService]="filterService"
                  [format]="'n0'">
                </number-range-filter>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column [minResizableWidth]="100" field="isActive" title="Active" [width]="30" filter="boolean">
              <kendo-grid-messages filterIsTrue="True" filterIsFalse="False">
              </kendo-grid-messages>
              <ng-template kendoGridCellTemplate let-dataItem>
                {{
                dataItem.isActive === true
                ? "True"
                : dataItem.isActive === false
                ? "False"
                : dataItem.isActive
                }}
              </ng-template>
            </kendo-grid-column>
          </kendo-grid>
        </div>
      </div>
    </div>
  </div>
</div>
