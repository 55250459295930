import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CompositeFilterDescriptor, SortDescriptor, filterBy } from '@progress/kendo-data-query';
import * as _ from 'lodash';
import { Paginator } from 'primeng/paginator';
import { Observable, Subject } from 'rxjs';
import { NavigationPath } from 'src/app/shared/class/navigation-path';
import { PreventBackButtonService } from 'src/app/shared/services/prevent-back-button.service';
import { UserManagementService } from '../../services/user-management.service';
import { UserRoleEditComponent } from '../user-role-edit/user-role-edit.component';
import { UserRoleAddComponent } from '../user-role-add/user-role-add.component';
import { UserMessages } from 'src/app/shared/class/user-messages';
import { ConfirmBox } from 'src/app/shared/class/confirm-box';
import { DataBindingDirective, PageChangeEvent } from '@progress/kendo-angular-grid';
import { FilterExpression } from '@progress/kendo-angular-filter';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { SelectedHeaderService } from 'src/app/shared/services/selected-header.service';
import { LoaderService } from 'src/app/shared/utility/loader/loader.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-user-role',
  templateUrl: './user-role.component.html',
  styleUrls: ['./user-role.component.css']
})
export class UserRoleComponent implements OnInit {
  @ViewChild('paginator', { static: true }) paginator!: Paginator
  userSearch: any;
  _navigationPath: NavigationPath = new NavigationPath();
  rolesList: any = [];
  userList: any = [];
  first: number = 0;
  payload: any = {};
  public multiple = false;
  filterData = JSON.parse(sessionStorage.getItem('userFilterStorage')!);
  _userMessages: UserMessages;
  _confirmBox: ConfirmBox;
  data: any = {};
  errorList: any[] = [];

  // Telrik start
  @ViewChild(DataBindingDirective) dataBinding!: DataBindingDirective;
  public buttonCount = 5;
  public sizes = [10, 25, 50];

  skip = this.filterData != null ? (this.filterData.pageNumber - 1) * this.filterData.pageSize : 0;
  pageSize = this.filterData != null ? this.filterData.pageSize : 10;
  pageNo: number = this.filterData != null ? this.filterData.pageNumber : 1;
  totalCount = this.filterData != null ? this.filterData.totalCount : 0;
  finalUserList: any = [];
  public gridFilter: CompositeFilterDescriptor = {
    logic: 'and',
    filters: [],
  };

  public recallList: any = [];
  public searchText: string = '';
  public defaultFilter: CompositeFilterDescriptor = { filters: [], logic: 'and' };
  public filterValue: CompositeFilterDescriptor = { ...this.defaultFilter };
  public masterRecallList: any[] = [];
  public isFilter: boolean = true;
  private $searchingText = new Subject<void>();
  public gridFilters: CompositeFilterDescriptor = { ...this.defaultFilter };

  public filters: FilterExpression[] = [
    {
      field: 'display_name',
      title: 'Display Name',
      editor: 'string',
    },
    {
      field: 'roleName',
      title: 'Role',
      editor: 'string',
    },
    {
      field: 'position',
      title: 'Position',
      editor: 'string',
    },
    {
      field: 'email',
      title: 'Email',
      editor: 'string',
    },
    {
      field: 'userName',
      title: 'User Name',
      editor: 'string',
    },
    {
      field: 'phoneNumber',
      title: 'Phone Number',
      editor: 'string',
    },
    {
      field: 'extension',
      title: 'Ext',
      editor: 'string',
    },
    {
      field: 'isActive',
      title: 'Active',
      editor: 'string',
    },
  ];

  public sort: SortDescriptor[] = [
    {
      field: this.filters[0].field,
      dir: "asc",
    }
  ];

  showError: boolean = false;
  errorMessage: any;
  // Telrik end

  searchResult$!: Observable<any>;
  private destroy$ = new Subject();
  datavalue: any;
  userData$!: Observable<any>;
  gridFilterArrayRoleName: any[] = [];
  filterForIsActiveStatus: any;
  gridFilterArrayIsActive: any;

  constructor(
    private _userManagementService: UserManagementService,
    private modalService: NgbModal,
    private _preventBack: PreventBackButtonService,
    private _selectedHeaderService: SelectedHeaderService,
    private _loaderService: LoaderService,
    private titleService: Title
  ) {

    this._userMessages = new UserMessages();
    this._confirmBox = new ConfirmBox();
    this._navigationPath = new NavigationPath();
    this.$searchingText.pipe(
      debounceTime(1000)
    ).subscribe(() => {
      this.getUserList();
    });
  }

  @HostListener('document:keypress', ['$event'])
  startSearch(event: KeyboardEvent) {
    if (event.code === "Enter") {
      this.onFilter()
    }
  }

  ngOnInit(): void {
    this.titleService.setTitle('User Management')
    this._preventBack.preventBackButton();
    this.getUserRole();
    this.filterForIsActiveStatus = ['true', 'false'];
    if (this.filterData?.gridFilterJson == '' || this.filterData?.gridFilterJson == null) {
      this.getUserList();
    } else {
      this.getUserListUsingFilterStorage()
    }

    this.searchResult$ = this._selectedHeaderService.getSelectedHeader();
    this.searchResult$.pipe(takeUntil(this.destroy$)).subscribe((data: any) => {
      this.datavalue = data;
    });

    //if grid filter applied then kendo filter is opened on load
  }

  onPageChange(event: any) {
    this.first = (event.page) * this.pageSize;
    this.pageNo = event.page + 1;
    this.pageSize = event.rows;
    this.getUserList();
  }

  closeError() {
    this.showError = false;
  }

  //to open User role Modal
  openEditUserRoleModal(item: any) {
    const modalRef = this.modalService.open(UserRoleEditComponent, {
      keyboard: false,
      backdrop: 'static',
      modalDialogClass: 'customeDialog',
    });
    modalRef.componentInstance.fromUserRole = item;
    modalRef.componentInstance.passEntry.subscribe((resp: any) => {
      if (resp == 'Edit user') {
        this.getUserList();
      }
    })
    modalRef.closed.subscribe((resp) => {
    });
  }

  //to open Add User role Modal
  openAddUserRoleModal() {
    const modalRef = this.modalService.open(UserRoleAddComponent, {
      keyboard: false,
      backdrop: 'static',
      modalDialogClass: 'customeDialog',
    });
    modalRef.componentInstance.passEntry.subscribe((resp: any) => {
      if (resp == 'Add User') {
        this.getUserList();
      }
    })
    modalRef.closed.subscribe((resp) => {
    });
  }

  clearCache() {
    this._userManagementService.clearCache().subscribe(
      (resp) => {

      },
    )
  }

  getUserList() {
    this.payload = {
      "pageNumber": this.pageNo,
      "pageSize": this.pageSize,
      "totalCount": this.totalCount,
      "searchText": this.searchText,
      "sortDir": this.sort[0].dir,
      "sortCol": this.sort[0].field,
      "gridFilterJson": this.gridFilters.filters != null && this.gridFilters.filters.length > 0 ? JSON.stringify(this.gridFilters) : "",
    }

    sessionStorage.setItem('userFilterStorage', JSON.stringify(this.payload));
    this.userData$ = this._userManagementService.getUserManagementList(this.payload);
    this.userData$.pipe(takeUntil(this.destroy$)).subscribe(
      (resp: any) => {
        this.payload.totalCount = resp.totalRecord;
        sessionStorage.setItem('userFilterStorage', JSON.stringify(this.payload));
        if (resp) {
          this.userList = resp.items[0].userInfo;
          this.userSearch = resp.items[0].userInfo;
          this.totalCount = resp.totalRecord;
          this.finalUserList = {
            data: this.userList,
            total: resp.totalRecord,
          };
        }
      },
      (err: any) => {
        if (err.error.status == 403) {
          this.showError = true;
        }
      }
    );

  }

  public gridFilterChange(filter: CompositeFilterDescriptor): void {
    this.gridFilters = { ...filter };
    if (this.gridFilters.filters.length > 0) {
      var roleNameIndex = this.gridFilters.filters.findIndex((x: any) => x.field == "roleName")
      if (roleNameIndex > -1 && this.gridFilterArrayRoleName.length == 0)
        this.gridFilters.filters.splice(roleNameIndex, 1)
      if (roleNameIndex < 0) this.gridFilterArrayRoleName = []
    }
    if (!this.gridFilters?.filters.length) {
      this.gridFilterArrayRoleName = [];
    }
    this.resetOptions();
    this.$searchingText.next();
  }

  resetOptions(): void {
    this.pageNo = 1;
    this.skip = 0;
    this.totalCount = 0;
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.skip = 0;
    this.pageNo = 1;
    this.sort = sort;
    this.totalCount = this.finalUserList.total;
    this.getUserList();
  }

  paginationChange(event: PageChangeEvent) {
    this.skip = event.skip;
    this.pageSize = event.take;
    this.pageNo = (event.take + event.skip) / event.take;
    this.totalCount = this.finalUserList.total;
    this.getUserList();
  }

  public filterChange(filter: CompositeFilterDescriptor): void {
    this.gridFilter = { ...filter };
    this.finalUserList.data = filterBy(this.masterRecallList, filter);
  }

  //To hide column filters
  columnFilter(value: boolean) {
    this.isFilter = !value;
  }

  //searching on all columns
  public onFilter(): void {
    let data = JSON.parse(sessionStorage.getItem('userFilterStorage')!)
    if (this.searchText === "" && data?.searchText === "") {
      return
    }
    this.resetOptions();
    this.$searchingText.next();
  }

  getUserListUsingFilterStorage() {
    this.filterData = JSON.parse(sessionStorage.getItem('userFilterStorage')!);
    this._userManagementService.getUserManagementList(this.filterData).subscribe(
      (resp) => {
        if (resp) {
          this.userList = resp.items[0].userInfo;
          this.userSearch = resp.items[0].userInfo;
          this.totalCount = resp.totalRecord;

          if (this.userList) {
            this.finalUserList = {
              data: this.userList,
              total: resp.totalRecord,
            };
            this._loaderService.hide();
          }
        }
        this.setDataUsingFilterStorage();
      },
      (err: any) => {
        this.showError = true;
        this.errorMessage =
          'Error : ' + err.message + ', Status: ' + err.status;
        this.errorList = [];
        this.errorList.push(this.errorMessage);
        this._loaderService.hide();
      }
    );
  }

  searchValue(inputValue: any) {
    this.searchText = inputValue;
  }

  //get user role method
  getUserRole() {
    this.rolesList = [];
    this._userManagementService.getUserRoleList().subscribe(
      (resp: any) => {
        if (resp) {
          this.rolesList = resp.rolesList;
        }
      });
  }

  public onRoleNameChange(event: any): void {
    if (this.gridFilters.filters == undefined) this.gridFilters = { logic: 'and', filters: [] };
    if (this.gridFilters.filters.length > 0) {
      var stateIndex = this.gridFilters.filters.findIndex((x: any) => x.field == "roleName")
      if (stateIndex > -1)
        this.gridFilters.filters.splice(stateIndex, 1)
      let stateFilter = {
        field: "roleName",
        operator: "contains",
        value: event.toString()
      }
      if (event.length > 0) this.gridFilters.filters.push(stateFilter);
    } else {
      let stateFilter = {
        field: "roleName",
        operator: "contains",
        value: event.toString()
      }
      this.gridFilters.filters.push(stateFilter)
    }
    this.resetOptions();
    this.$searchingText.next();
  }

  public onIsActiveChange(event: any): void {
    if (this.gridFilters.filters == undefined) this.gridFilters = { logic: 'and', filters: [] };
    if (this.gridFilters.filters.length > 0) {
      var stateIndex = this.gridFilters.filters.findIndex((x: any) => x.field == "isActive")
      if (stateIndex > -1)
        this.gridFilters.filters.splice(stateIndex, 1)
      let stateFilter = {
        field: "isActive",
        operator: "contains",
        value: event.toString() === "true" ? "1" : event.toString() === "false" ? "0" : "1"
      }
      if (event.length > 0) this.gridFilters.filters.push(stateFilter);
    } else {
      let stateFilter = {
        field: "isActive",
        operator: "contains",
        value: event.toString() === "true" ? "1" : event.toString() === "false" ? "0" : "1"
      }
      this.gridFilters.filters.push(stateFilter)
    }
    this.resetOptions();
    this.$searchingText.next();
  }

  setDataUsingFilterStorage() {
    this.filterData = JSON.parse(sessionStorage.getItem('userFilterStorage')!)
    if (this.filterData) {
      this.gridFilters = this.filterData.gridFilterJson ? JSON.parse(this.filterData.gridFilterJson) : ""
      this.searchText = this.filterData.searchText;

      //for state grid filter
      if (this.filterData.gridFilterJson) {
        let roleNameFilterData = JSON.parse(this.filterData.gridFilterJson).filters.filter((x: any) => x.field == "roleName")[0]?.value;
        if (roleNameFilterData) {
          this.gridFilterArrayRoleName = roleNameFilterData?.split(',')
        }

        this.gridFilterArrayIsActive = JSON.parse(this.filterData.gridFilterJson).filters.filter((x: any) => x.field == "isActive")[0]?.value;
        this.gridFilterArrayIsActive = this.gridFilterArrayIsActive === "1" ? "true" : "false"
      }
    }
  }
}
