import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmBox } from 'src/app/shared/class/confirm-box';
import { UserMessages } from 'src/app/shared/class/user-messages';
import { AbstractControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { UserManagementService } from '../../services/user-management.service';
import { KeyboardValidation } from 'src/app/shared/services/caffeineValidators/keyboard-validation';
import { AddprospectScreen } from 'src/app/features/prospect/error-message/addprospect-screen';
import { SelectedHeaderService } from 'src/app/shared/services/selected-header.service';
import { WhiteBoardActivityService } from 'src/app/features/white-board/services/white-board-activity.service';
import { LookupManagerComponent } from 'src/app/features/white-board/components/add-whiteboard/lookup-manager/lookup-manager.component';
import { DatePipe } from '@angular/common';
import { ErrorBox } from 'src/app/shared/class/error-box';
import { RemoveWhiteSpacesPipe } from 'src/app/shared/pipes/remove-white-spaces.pipe';
import { AddprospectService } from 'src/app/features/prospect/services/addprospect.service';

@Component({
  selector: 'app-user-role-edit',
  templateUrl: './user-role-edit.component.html',
  styleUrls: ['./user-role-edit.component.css']
})
export class UserRoleEditComponent implements OnInit {
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  @Input() fromUserRole: any;
  updateUserForm: any;
  _confirmBox: ConfirmBox;
  _userMessages: UserMessages;
  rolesList: any;
  userList: any[] = [];
  submitted: boolean = false;
  showError: boolean = false;
  errorMessage: any;
  errorList: any[] = [];
  isActive: any;
  branchList: any = [];
  businessUnitList: any = [];
  userProfileData: any = [];
  userAccessLevelList: any = [];
  phoneInvalid: boolean = false;
  _AddprospectScreen: AddprospectScreen;
  emailRxjs = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  accountManagerlist: any;
  pId: any;
  lookupData: any;
  filesToUpload: any;
  fileName: any = "";
  fileSize: any;
  userPicName: any = ""
  filePath: any = "";
  producerLevelList: any = [];
  regionList: any;
  _errorBox: ErrorBox;
  statesList: any;
  inValidZip: boolean = false;
  displayRequired: boolean = false;

  constructor(public activeModal: NgbActiveModal,
    private _formBuilder: UntypedFormBuilder,
    private _userManagementService: UserManagementService,
    public ValidateKey: KeyboardValidation,
    private _selectedHeaderService: SelectedHeaderService,
    private _addWBAService: WhiteBoardActivityService,
    private _removeWhiteSpacesPipe: RemoveWhiteSpacesPipe,
    private _AddprospectService: AddprospectService,
    private modalService: NgbModal,
    private _datepipe: DatePipe,
  ) {
    this._confirmBox = new ConfirmBox();
    this._userMessages = new UserMessages();
    this.updateUserControl();
    this._AddprospectScreen = new AddprospectScreen();
    this._errorBox = new ErrorBox();
  }

  ngOnInit(): void {
    // this.getUserProfile(this.fromUserRole.userId)
    this.getUserProfile(this.fromUserRole)
    this.getUserRole();
    this.getBranchList();
    this.getAccessLevelList();
    this.getProducerLevelList();
    this.getRegionList();
    this.getStates();
    this.onChnages();
  }

  updateUserControl() {
    this.updateUserForm = this._formBuilder.group({
      userId: ['', Validators.required],
      firstName: [''],
      lastName: [''],
      directContact: [''],
      phoneNumber: ['', [Validators.required]],
      extension: [''],
      email: ['', [Validators.required, Validators.pattern(this.emailRxjs)]],
      userEmail: [''],
      branchId: ['', [Validators.required]],
      userRoleId: ['', [Validators.required]],
      managerId: [{ value: '', disabled: false }],
      epicLookupCode: [''],
      businessUnit: [''],
      isActive: [''],
      accessLevel: [''],
      region: [''],
      linkedInUrl: [''],
      jobTitle: [''],
      oid: ['', [Validators.required]],
      serviceDescription: [''],
      producerLevel: [''],
      producerLevelDate: [''],
      street1: ['', [Validators.maxLength(60)]],
      street2: ['', [Validators.maxLength(60)]],
      city: ['', [Validators.maxLength(60)]],
      state: [''],
      zipCode: ['']
    })
  }

  // Form controls
  get f() {
    return this.updateUserForm.controls;
  }

  updateUserProfile() {
    this.submitted = true;
    if (this.updateUserForm.invalid) {
      return
    }
    let data = this.updateUserForm.value;
    const userEmailArray = data.userEmail.split('@');
    if (userEmailArray.length > 0) {
      this.userPicName = userEmailArray[0].toString();
    }
    else {
      const fileNameArray = this.filesToUpload.name
      if (fileNameArray.length > 0) {
        this.userPicName = fileNameArray[0].toString();
      }
    }

    let producerLevelDate: any = data.producerLevelDate ? this._datepipe.transform(data.producerLevelDate, 'yyyy-MM-dd HH:mm:ss') : null;
    const formData = new FormData();
    formData.append('userId', data.userId);
    formData.append('firstName', data.firstName);
    formData.append('lastName', data.lastName);
    formData.append('directContact', data.directContact);
    formData.append('phoneNumber', data.phoneNumber);
    formData.append('extension', data.extension);
    formData.append('email', data.email);
    formData.append('userEmail', data.userEmail);
    formData.append('branchId', data.branchId == "Select Branch" ? 0 : data.branchId);
    formData.append('businessUnit', data.businessUnit);
    formData.append('isActive', data.isActive);
    formData.append('userRoleId', data.userRoleId.toString());
    formData.append('managerId', data.managerId ? data.managerId?.id : "");
    formData.append('epicLookupCode', data.epicLookupCode ? data.epicLookupCode : "");
    formData.append('accessLevel', data.accessLevel == 'Select Access Level' ? null : data.accessLevel);
    formData.append('regionCode', data.region == 'Select Region' ? null : data.region);
    formData.append('linkedInUrl', data.linkedInUrl);
    formData.append('jobTitle', data.jobTitle);
    formData.append('oid', data.oid);
    formData.append('serviceDescription', data.serviceDescription);
    formData.append('UserPic', this.filesToUpload ? this.filesToUpload : null);
    formData.append('UserPicName', this.filesToUpload ? this.userPicName : "");
    formData.append('producerLevel', data.producerLevel || "");
    formData.append('producerLevelDate', producerLevelDate || "");
    formData.append('Street1', data.street1 || "");
    formData.append('Street2', data.street2 || "");
    formData.append('City', data.city || "");
    formData.append('State', data.state || "");
    formData.append('ZipCode', data.zipCode || "");
    this._userManagementService.updateUser(formData, this.fromUserRole.userId).subscribe((resp: any) => {
      if (resp) {
        this.errorList = [];
        this.showError = false;
        this.passEntry.emit('Edit user')
        this._selectedHeaderService.loginUserId.next(this.fromUserRole.userId);
        this.closeModal();
      }
    },
      (err: any) => {
        this.errorList = [];
        if (err.status !== 200) {
          if (err.status === 400) {
            this.showError = true;
            let obj = err.error.errors;
            var arr = Object.keys(obj).map((key) => ({
              type: key,
              value: obj[key],
            }));
            this.errorList = [];
            this.errorMessage = arr[0].value[0];
            this.errorList.push(this.errorMessage);
          } else {
            this.showError = true;
            this.errorMessage = err.message;
            this.errorList.push(this.errorMessage);
          }
        }
      })
  }

  // User access level
  getAccessLevelList() {
    this._userManagementService.userAccessLevel().subscribe((resp: any) => {
      if (resp) {
        this.userAccessLevelList = resp.userAccessLevelList;
      }
    })
  }

  // Branch list
  getBranchList() {
    this._userManagementService.branchList().subscribe((resp: any) => {
      if (resp) {
        this.branchList = resp.userProfileBranchList;
      }
    })
  }

  // Business unit list
  getBusinessUnitList() {
    this._userManagementService.businessUnitList().subscribe((resp: any) => {
      if (resp) {
        this.businessUnitList = resp.masterBusinessUnitList;
      }
    })
  }
  // Status maintaine 
  userStatus(val: any) {
    this.isActive = !val
  }
  setUpdateUserControl(item: any) {
    if (item) {
      this.isActive = item.isActive
      this.updateUserForm.patchValue({
        userId: item.userId,
        firstName: item.firstName,
        lastName: item.lastName,
        directContact: item.mobile == 'null' ? '' : item.mobile,
        phoneNumber: item.phoneNumber,
        extension: !item.extension || item.extension === "Null" ? "" : item.extension,
        email: item.email,
        userEmail: item.userName,
        accessLevel: item.accessLevel ? item.accessLevel : "",
        businessUnit: item.businessUnit,
        isActive: this.isActive,
        userRoleId: item.userRoleId ? item.userRoleId.split(',') : [],
        managerId: {
          id: item.managerId,
          value: item.managerName
        },
        jobTitle: item.position,
        oid: item.oid,
        producerLevel: item.producerLevel == null ? "" : item.producerLevel,
        producerLevelDate: item.producerLevelDate ? new Date(item.producerLevelDate) : null
      })
    }
  }

  //get user role method
  getUserRole() {
    this.rolesList = [];
    this._userManagementService.getUserRoleList().subscribe(
      (resp: any) => {
        if (resp) {
          this.rolesList = resp.rolesList;
        }
      });
  }

  getUserProfile(id: any) {
    if (id) {
      let userId = id.userId;
      this._userManagementService.userProfile(userId).subscribe((resp: any) => {
        this.userProfileData = resp?.userProfileList;
        if (this.userProfileData.length > 0) {
          this.updateUserForm.patchValue({
            branchId: this.userProfileData[0]?.branch == 0 ? "" : this.userProfileData[0]?.branch,
            epicLookupCode: this.userProfileData[0]?.lookupCode,
            linkedInUrl: this.userProfileData[0]?.linkedInUrl,
            serviceDescription: this.userProfileData[0]?.serviceDescription,
            region: this.userProfileData[0]?.regionCode,
            street1: this.userProfileData[0]?.street1 ? this.userProfileData[0]?.street1 : "",
            street2: this.userProfileData[0]?.street2 ? this.userProfileData[0]?.street2 : "",
            city: this.userProfileData[0]?.city ? this.userProfileData[0]?.city : "",
            state: this.userProfileData[0]?.state ? this.userProfileData[0]?.state : "",
            zipCode: this.userProfileData[0]?.zipCode ? this.userProfileData[0]?.zipCode : ""
          })
          this.fileName = this.userProfileData[0]?.userPicName;
          this.filePath = this.userProfileData[0]?.userPicPath;
        } else {
          this.updateUserForm.patchValue({
            branchId: "",
            epicLookupCode: '',
            linkedInUrl: '',
          })
        }

      })
      this.setUpdateUserControl(this.fromUserRole)
    }
  }

  //to calcel the process and back to prospect list screen
  cancel() {
    if (!this.updateUserForm.dirty) {
      this.closeModal();
    } else {
      this._confirmBox.message = this._userMessages.cancelButtonMessage;
      this._confirmBox.isVisible = true;
    }
  }

  confirmBoxCancel() {
    this._confirmBox.isVisible = false;
    this.activeModal.close();
  }

  closeModal() {
    this.activeModal.close();
  }

  closeError() {
    this.showError = false;
  }

  onChnages() {
    this.updateUserForm.get('directContact')?.valueChanges.subscribe((val: any) => {
      if ((val == "" || val == null)) {
        this.phoneInvalid = true; this.submitted = true
      }
      else this.phoneInvalid = false
    })
  }

  getCompanyList(fieldName: any, val: any) {
    this.showError = false;
    if (fieldName && val.query) {
      this._addWBAService.getTypeheadList(fieldName, val.query).subscribe(resp => {
        if (fieldName == 'accountManager') {
          this.accountManagerlist = resp.userList;
        }
      })
    }
  }

  selectItem(item: any, type: any) {
    this.showError = false;
    this.pId = item?.id;
    if (type == 'managerId') {
      this.updateUserForm.controls['managerId'].setValue({
        id: item.userId,
        value: item.userName
      });
    }
  }

  openLookupModal(value: any) {
    let data = {
      "lookUpType": value,
      "rowCount": 10
    }
    let lookupcomponent: any;
    this._addWBAService.getWhiteBoardActivityLookUp(data).subscribe((response: any) => {
      if (response) {
        this.lookupData = response;
        if (value === "AccountManager") {
          lookupcomponent = LookupManagerComponent;
        }

        const modalRef = this.modalService.open(lookupcomponent, {
          keyboard: false,
          backdrop: 'static',
          modalDialogClass: 'customeDialog1'
        });
        modalRef.closed.subscribe(resp => {
        })

        modalRef.componentInstance.lookupData = this.lookupData;
        modalRef.componentInstance.messageEvent.subscribe((data: any) => {
          if (value === "AccountManager") {
            this.updateUserForm.controls['managerId'].patchValue({
              id: data.userId,
              value: data.userName
            });
          }
        });
      }
    });
  }

  onFileChange(event: any) {
    if (event.target.files.length > 0) {
      const fileType = event.target.files[0].name.split('.')[1];
      if (fileType === 'png' || fileType === 'jpg' || fileType === 'jpeg') {
        const file = event.target.files[0];

        for (var i = 0; i < event.target.files.length; i++) {
          var size = event.target.files[i].size;
          // fileSize in MB
          this.fileSize = size / 1024 / 1024;

          if (this.fileSize > 1) {
            this._errorBox.message = "Image size should be less than or equal to 1 MB.";
            this._errorBox.isVisible = true;
          }
          else {
            this.filesToUpload = file;
          }
        }
      }
      else {
        this._errorBox.message = "Only jpg/jpeg and png files are allowed.";
        this._errorBox.isVisible = true;
      }
    }

  }

  fileOpenInNewTab(fileName: string, fileUrl: any) {
    let html = '';

    html += '<html><head><title>' + fileName + '</title><custom-style><style>#title {Display:none !important}</style></custom-style> <script>';
    html += ' var cover = document.createElement("div");';
    html += ' let css = "position: fixed;pointer-events: none;top: 0;left: 0;width: 100vw;height: 100vh;background-color: #3aa757;mix-blend-mode: multiply;z-index: 1;"';
    html += 'cover.setAttribute("style", css);document.body.appendChild(cover);</script></head>';
    html += '<body style="margin:0!important">';
    html += '<div class="pdf"><embed width="100%" height="100%" src="' + fileUrl + '" type="application/pdf" /></div>';
    html += '</body>';
    html += '</html>';
    setTimeout(() => {
      let win: Window | null = null
      win = window.open("", "_blank");
      win?.document.write(html);
    }, 0);
  }

  viewDocument() {
    this.fileOpenInNewTab(this.fileName, this.filePath);
  }

  changeDate(event: any) {
    this.updateUserForm.patchValue({
      producerLevelDate: this.f.producerLevelDate.value ? this._datepipe.transform(this.f.producerLevelDate.value, 'MM/dd/YYYY HH:mm:ss') : null
    })
  }

  onProducerLevelChange(event: any) {
    const selectedValue = event.target.value;
    if (selectedValue) {
      this.updateUserForm.patchValue({ producerLevelDate: new Date() });
    }
    else {
      this.updateUserForm.patchValue({ producerLevelDate: null });
    }
  }

  // get Producer Level List
  getProducerLevelList() {
    this._userManagementService.getProducerLevelList().subscribe((resp: any) => {
      if (resp) {
        this.producerLevelList = resp.producerLevelList;
      }
    })
  }

  // get Region List
  getRegionList() {
    this._userManagementService.getRegionList().subscribe((resp: any) => {
      if (resp) {
        this.regionList = resp.region;
      }
    })
  }

  errorBoxCancel() {
    this._errorBox.isVisible = false;
  }

  setAddressValidation(value: any) {
    if (value) {
      this.updateUserForm.get('city')?.setValidators([Validators.required]);
      this.updateUserForm.get('state')?.setValidators([Validators.required]);
      this.updateUserForm.get('zipCode')?.setValidators([Validators.required]);
      this.displayRequired = true;
    }
    else {
      this.updateUserForm.get('city')?.setValidators(null);
      this.updateUserForm.get('state')?.setValidators(null);
      this.updateUserForm.get('zipCode')?.setValidators(null);
      this.displayRequired = false;
    }
    this.updateUserForm.get('city')?.updateValueAndValidity({ emitEvent: false });
    this.updateUserForm.get('state')?.updateValueAndValidity({ emitEvent: false });
    this.updateUserForm.get('zipCode')?.updateValueAndValidity({ emitEvent: false });
  }

  removeSpaces(element: any, control?: AbstractControl) {
    var removeSpaces;
    removeSpaces = this._removeWhiteSpacesPipe.transform(element.target.value, '');
    element.target.value = removeSpaces;
    control?.setValue(element.target.value);
  }

  getStates() {
    this._AddprospectService.getProspectStates().subscribe((resp) => {
      this.statesList = resp.statesList;
      this.updateUserForm.patchValue({ state: this.userProfileData[0]?.state })
    });
    this.setAddressValidation(this.fromUserRole.street1);
  }

  setZipCode(val: any) {
    // let val = this.f.producerLevelDate.value
    let zipCodevalue;
    if (val) {
      if (val.length >= 5) {
        zipCodevalue = this.ValidateKey.formatZipCode(val);
        this.updateUserForm.patchValue({ zipCode: val })
      }
      if ((val.length < 5) || (val.length > 5 && val.length < 10))
        this.inValidZip = true;
      else
        this.inValidZip = false;
    }
  }

  setCityStateZipValidation(event: any) {
    if (event.target.value) {
      this.updateUserForm.get('city')?.setValidators([Validators.required]);
      this.updateUserForm.get('state')?.setValidators([Validators.required]);
      this.updateUserForm.get('zipCode')?.setValidators([Validators.required]);
      this.displayRequired = true;
    }
    else {
      this.updateUserForm.get('city')?.setValidators(null);
      this.updateUserForm.get('state')?.setValidators(null);
      this.updateUserForm.get('zipCode')?.setValidators(null);
      this.displayRequired = false;
    }
    this.updateUserForm.get('city')?.updateValueAndValidity({ emitEvent: false });
    this.updateUserForm.get('state')?.updateValueAndValidity({ emitEvent: false });
    this.updateUserForm.get('zipCode')?.updateValueAndValidity({ emitEvent: false });
  }
}
