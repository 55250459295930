<div class="main-body-con">
    <div class="page-container pt-4">
        <div class="page-hdr-outer ps-4 pe-4">
            <div class="page-hdr flex-div space-between">
                <h2>Role Permission Management</h2>
                <button (click)="navigateToUser()" class="btn btn-border createbt bck">
                    <span _ngcontent-wag-c828=""><img _ngcontent-wag-c828="" src="assets/images/back.svg" alt=""
                            class="plus-icon"></span>
                </button>
            </div>



            <div class="row" *ngIf="showError">
                <div class="col-12">
                    <div class="alert alert-danger alert-dismissible fade show my-2 " role="alert">
                        <ul class="view-error">
                            <li *ngFor="let err of errorList">{{err}}</li>
                        </ul>
                        <button type="button" class="btn-close" (click)="closeError()" data-bs-dismiss="alert"
                            aria-label="Close"></button>
                    </div>
                </div>
            </div>
            <div class="search-panel mb-3 row g-0">

                <div class=" flex-div col-12 col-sm-8 g-0">

                    <label class="pe-3">Role :</label>
                    <select class="select-cs form-select col-12 col-sm-5" #selectedValue
                        (change)="getRolePermission(selectedValue.value)">
                        <option value="" disabled selected>Select Role</option>
                        <option *ngFor="let role of rolesList" value="{{role.id}}">
                            {{ role.roleName }}
                        </option>
                    </select>

                </div>

            </div>

            <!-- <div class="mt-3">
                <h2>Role Permission</h2>
                <ng-container *ngFor="let permision of permissionList">
                    <div class="pt-3 row">
                        <div class="col-md-2">{{permision.permissionDisplayName}}</div>
                        <div class="col-md-1">
                            <input type="checkbox" [checked]="permision.checked"
                                (click)="getPermissionId(permision.permissionId, $event)" class="form-check-input"
                                value="{{permision.permissionId}}" />
                        </div>
                    </div>
                </ng-container>
            </div> -->

            <div class="grid-con mt-3 p-3 role-mgmt">
                <h2>Role Permission</h2>
                <div class="row-role">

                    <div class="card" *ngFor="let permision of result">
                        <div class="card-header">
                            <h3>{{permision.module }}</h3>
                        </div>
                        <div class="card-body">
                            <ng-container *ngFor="let value of permision.values">
                                <div class="d-flex align-items-center space-between pb-2 ">

                                    <label>{{value.permissionGroupDisplayName}}</label>
                                    <div>
                                        <input type="checkbox" [checked]="value.checked" (click)="getPermissionId(value.id
                              , $event)" class="form-check-input" value="{{permision.id
                              }}" />
                                    </div>

                                </div>
                            </ng-container>
                        </div>
                    </div>



                </div>



            </div>
            <div class="flex-div justify-content-end pt-3 pb-3 mb-5">
                <button class="btn btn-primary" (click)="saveRolePermission()">
                    Save
                </button>
                <!-- <button class="btn btn-border create" (click)="navigateToUser()"><span><img src="assets/images/back.svg"
                          alt="" class="plus-icon"></span><label>Back
                  </label></button> -->
            </div>
        </div>

    </div>
</div>