<div class="main-body-con">
    <div class="page-container pt-4">
      <div class="page-hdr-outer ps-4 pe-4">
        <div class="page-hdr">
          <h2>User Access</h2>
        </div>
         <div class="mt-3">
          <h2>User List :</h2>
          <div class="row">
            <div class="col-md-3">
              <select class="select-cs form-select" #id (change)="getUsersDetail(id.value)">
                <option value="" disabled selected>Select user</option>
                <ng-container *ngFor="let user of userList">
                    <ng-container  *ngFor="let role of user.roles">
                        <ng-container *ngIf="role.roleName == 'Client Executive' || role.roleName == 'System Client Manager'">
                            <option value="{{user.userId}}"> {{ user.display_name }}</option>
                        </ng-container>
                    </ng-container>
                 </ng-container>
              </select>
            </div>
          </div>
        </div>

        <div class="mt-5">
            <h2>Access List :</h2>
            <div class="row">
              <div class="col-md-3">
                <select class="select-cs form-select" #id1 (change)="getUsers(id1.value)">
                  <option value="" disabled selected>Select Access</option>
                  <option *ngFor="let user of accessList" value="{{user.accessLevelValue}}">
                    {{ user.accessLevelDisplayName }}
                  </option>
                </select>
              </div>
            </div>
        </div>
      </div>
      <div class="col-12 col-sm-12 col-md-12 pt-3 mt-4" >
          <button  [disabled]="isValid" class="btn btn-primary me-4" (click)="save()">
            Save
          </button>
        </div>
    </div>
  </div>
