import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmBox } from 'src/app/shared/class/confirm-box';
import { UserMessages } from 'src/app/shared/class/user-messages';
import { AbstractControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { UserManagementService } from '../../services/user-management.service';
import { KeyboardValidation } from 'src/app/shared/services/caffeineValidators/keyboard-validation';
import { AddprospectScreen } from 'src/app/features/prospect/error-message/addprospect-screen';
import { WhiteBoardActivityService } from 'src/app/features/white-board/services/white-board-activity.service';
import { LookupManagerComponent } from 'src/app/features/white-board/components/add-whiteboard/lookup-manager/lookup-manager.component';
import { DatePipe } from '@angular/common';
import { ErrorBox } from 'src/app/shared/class/error-box';
import { RemoveWhiteSpacesPipe } from 'src/app/shared/pipes/remove-white-spaces.pipe';
import { AddprospectService } from 'src/app/features/prospect/services/addprospect.service';

@Component({
  selector: 'app-user-role-add',
  templateUrl: './user-role-add.component.html',
  styleUrls: ['./user-role-add.component.css']
})
export class UserRoleAddComponent implements OnInit {
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  addUserForm: any;
  _confirmBox: ConfirmBox;
  _userMessages: UserMessages;
  rolesList: any;
  userList: any[] = [];
  submitted: boolean = false;
  showError: boolean = false;
  errorMessage: any;
  errorList: any[] = [];
  branchList: any = [];
  businessUnitList: any = [];
  userProfileData: any = [];
  userAccessLevelList: any = [];
  phoneInvalid: boolean = false;
  _AddprospectScreen: AddprospectScreen;
  emailRxjs = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  accountManagerlist: any;
  lookupData: any;

  filesToUpload: any;
  fileName: any;
  fileSize: any;
  userPicName: any = ""
  producerLevelList: any = [];

  _errorBox: ErrorBox;
  statesList: any;
  inValidZip: boolean = false;
  displayRequired: boolean = false;

  constructor(public activeModal: NgbActiveModal,
    private _formBuilder: UntypedFormBuilder,
    private _userManagementService: UserManagementService,
    public ValidateKey: KeyboardValidation,
    private _addWBAService: WhiteBoardActivityService,
    private _removeWhiteSpacesPipe: RemoveWhiteSpacesPipe,
    private _AddprospectService: AddprospectService,
    private modalService: NgbModal,
    private _datepipe: DatePipe,
  ) {
    this._confirmBox = new ConfirmBox();
    this._userMessages = new UserMessages();
    this.addUserControl();
    this._AddprospectScreen = new AddprospectScreen();
    this._errorBox = new ErrorBox();
  }

  ngOnInit(): void {
    this.getUserRole();
    this.getBranchList();
    this.getAccessLevelList();
    this.getProducerLevelList();
    this.getStates();
    this.onChanges();
  }

  addUserControl() {
    this.addUserForm = this._formBuilder.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      directContact: [''],
      phoneNumber: ['', [Validators.required]],
      extension: [''],
      email: ['', [Validators.required, Validators.pattern(this.emailRxjs)]],
      userEmail: ['', [Validators.required]],
      branchId: ['', [Validators.required]],
      userRoleId: ['', [Validators.required]],
      managerId: [''],
      epicLookupCode: [''],
      businessUnit: [''],
      isActive: [''],
      accessLevel: [''],
      linkedInUrl: [''],
      jobTitle: ['', [Validators.required]],
      oid: ['', [Validators.required]],
      serviceDescription: [''],
      producerLevel: [''],
      producerLevelDate: [''],
      street1: ['', [Validators.maxLength(60)]],
      street2: ['', [Validators.maxLength(60)]],
      city: ['', [Validators.maxLength(60)]],
      state: [''],
      zipCode: ['']
    })
    this.addUserForm.patchValue({ isActive: true })
  }

  // Form controls
  get f() {
    return this.addUserForm.controls;
  }

  updateUserProfile() {
    this.submitted = true;
    if (this.addUserForm.invalid) {
      return
    }
    let data = this.addUserForm.value;
    const userEmailArray = data.userEmail.split('@');
    if (userEmailArray.length > 0) {
      this.userPicName = userEmailArray[0].toString();
    }
    else {
      const fileNameArray = this.filesToUpload.name
      if (fileNameArray.length > 0) {
        this.userPicName = fileNameArray[0].toString();
      }
    }

    let producerLevelDate: any = data.producerLevelDate ? this._datepipe.transform(data.producerLevelDate, 'yyyy-MM-dd HH:mm:ss') : null;
    const formData = new FormData();
    formData.append('firstName', data.firstName);
    formData.append('lastName', data.lastName);
    formData.append('userName', data.userEmail);
    formData.append('phoneNumber', data.phoneNumber);
    formData.append('extension', data.extension || "");
    formData.append('email', data.email);
    formData.append('directContact', data.directContact || "");
    formData.append('branchId', data.branchId ? data.branchId : "");
    formData.append('userRoleId', data.userRoleId.toString() || 0);
    formData.append('jobTitle', data.jobTitle || "");
    formData.append('managerId', data.managerId ? data.managerId?.id : "");
    formData.append('businessUnit', data.businessUnit || "");
    formData.append('accessLevel', data.accessLevel ? data.accessLevel : "");
    formData.append('epicLookupCode', data.epicLookupCode ? data.epicLookupCode : "");
    formData.append('linkedInUrl', data.linkedInUrl || "");
    formData.append('oid', data.oid || "");
    formData.append('serviceDescription', data.serviceDescription || "");
    formData.append('isActive', data.isActive);
    formData.append('UserPic', this.filesToUpload ? this.filesToUpload : null);
    formData.append('UserPicName', this.filesToUpload ? this.userPicName : "");
    formData.append('producerLevel', data.producerLevel || "");
    formData.append('producerLevelDate', producerLevelDate || "");
    formData.append('Street1', data.street1 || "");
    formData.append('Street2', data.street2 || "");
    formData.append('City', data.city || "");
    formData.append('State', data.state || "");
    formData.append('ZipCode', data.zipCode || "");

    this._userManagementService.addUser(formData).subscribe((resp: any) => {
      if (resp) {
        this.errorList = [];
        this.showError = false;
        this.passEntry.emit('Add User')
        this.closeModal();
      }
    },
      (err: any) => {
        this.errorList = [];
        if (err.status !== 200) {
          if (err.status === 400) {
            this.showError = true;
            let obj = err.error.errors;
            var arr = Object.keys(obj).map((key) => ({
              type: key,
              value: obj[key],
            }));
            this.errorList = [];
            this.errorMessage = arr[0].value[0];
            this.errorList.push(this.errorMessage);
          } else if (err.status === 412) {
            this.showError = true;
            this.errorMessage = err.error.detail;
            this.errorList.push(this.errorMessage);
          }
          else {
            this.showError = true;
            this.errorMessage = err.message;
            this.errorList.push(this.errorMessage);
          }
        }
      })
  }

  // User access level
  getAccessLevelList() {
    this._userManagementService.userAccessLevel().subscribe((resp: any) => {
      if (resp) {
        this.userAccessLevelList = resp.userAccessLevelList;
      }
    })
  }

  // Branch list
  getBranchList() {
    this._userManagementService.branchList().subscribe((resp: any) => {
      if (resp) {
        this.branchList = resp.userProfileBranchList;
      }
    })
  }

  // Status maintaine 
  userStatus(val: any) {
    this.addUserForm.patchValue({ isActive: !val })
  }

  //get user role method
  getUserRole() {
    this.rolesList = [];
    this._userManagementService.getUserRoleList().subscribe(
      (resp: any) => {
        if (resp) {
          this.rolesList = resp.rolesList;
        }
      });
  }

  //to calcel the process and back to prospect list screen
  cancel() {
    if (!this.addUserForm.dirty) {
      this.closeModal();
    } else {
      this._confirmBox.message = this._userMessages.cancelButtonMessage;
      this._confirmBox.isVisible = true;
    }
  }

  confirmBoxCancel() {
    this._confirmBox.isVisible = false;
    this.activeModal.close();
  }

  closeModal() {
    this.activeModal.close();
  }

  closeError() {
    this.showError = false;
  }

  onChanges() {
    this.addUserForm.get('directContact')?.valueChanges.subscribe((val: any) => {
      if ((val == "" || val == null)) {
        this.phoneInvalid = true; this.submitted = true
      }
      else this.phoneInvalid = false
    })
  }

  getCompanyList(fieldName: any, val: any) {
    this.showError = false;
    if (fieldName && val.query) {
      this._addWBAService.getTypeheadList(fieldName, val.query).subscribe(resp => {
        if (fieldName == 'accountManager') {
          this.accountManagerlist = resp.userList;
        }
      })
    }
  }

  selectItem(item: any, type: any) {
    this.showError = false;
    if (type == 'managerId') {
      this.addUserForm.controls['managerId'].setValue({
        id: item.userId,
        value: item.userName
      });
    }
  }

  openLookupModal(value: any) {
    let data = {
      "lookUpType": value,
      "rowCount": 10
    }
    let lookupcomponent: any;
    this._addWBAService.getWhiteBoardActivityLookUp(data).subscribe((response: any) => {
      if (response) {
        this.lookupData = response;
        if (value === "AccountManager") {
          lookupcomponent = LookupManagerComponent;
        }

        const modalRef = this.modalService.open(lookupcomponent, {
          keyboard: false,
          backdrop: 'static',
          modalDialogClass: 'customeDialog1'
        });
        modalRef.closed.subscribe(resp => {
        })

        modalRef.componentInstance.lookupData = this.lookupData;
        modalRef.componentInstance.messageEvent.subscribe((data: any) => {
          if (value === "AccountManager") {
            this.addUserForm.controls['managerId'].patchValue({
              id: data.userId,
              value: data.userName
            });
          }
        });
      }
    });
  }

  onFileChange(event: any) {
    if (event.target.files.length > 0) {
      const fileType = event.target.files[0].name.split('.')[1];
      if (fileType === 'png' || fileType === 'jpg' || fileType === 'jpeg') {
        const file = event.target.files[0];

        for (var i = 0; i < event.target.files.length; i++) {
          var size = event.target.files[i].size;
          // fileSize in MB
          this.fileSize = size / 1024 / 1024;

          if (this.fileSize > 1) {
            this._errorBox.message = "Image size should be less than or equal to 1 MB.";
            this._errorBox.isVisible = true;
          }
          else {
            this.filesToUpload = file;
          }
        }
      }
      else {
        this._errorBox.message = "Only jpg/jpeg and png files are allowed.";
        this._errorBox.isVisible = true;
      }
    }

  }

  changeDate(event: any) {
    this.addUserForm.patchValue({
      producerLevelDate: this.f.producerLevelDate.value ? this._datepipe.transform(this.f.producerLevelDate.value, 'MM/dd/YYYY HH:mm:ss') : null
    })
  }

  onProducerLevelChange(event: any) {
    const selectedValue = event.target.value;
    if (selectedValue) {
      this.addUserForm.patchValue({ producerLevelDate: new Date() });
    }
    else {
      this.addUserForm.patchValue({ producerLevelDate: null });
    }
  }

  // get Producer Level List
  getProducerLevelList() {
    this._userManagementService.getProducerLevelList().subscribe((resp: any) => {
      if (resp) {
        this.producerLevelList = resp.producerLevelList;
      }
    })
  }

  errorBoxCancel() {
    this._errorBox.isVisible = false;
  }

  removeSpaces(element: any, control?: AbstractControl) {
    var removeSpaces;
    removeSpaces = this._removeWhiteSpacesPipe.transform(element.target.value, '');
    element.target.value = removeSpaces;
    control?.setValue(element.target.value);
  }

  getStates() {
    this._AddprospectService.getProspectStates().subscribe((resp) => {
      this.statesList = resp.statesList;
    });
  }

  setZipCode(val: any) {
    // let val = this.f.producerLevelDate.value
    let zipCodevalue;
    if (val) {
      if (val.length >= 5) {
        zipCodevalue = this.ValidateKey.formatZipCode(val);
        this.addUserForm.patchValue({ zipCode: val })
      }
      if ((val.length < 5) || (val.length > 5 && val.length < 10))
        this.inValidZip = true;
      else
        this.inValidZip = false;
    }
  }

  setCityStateZipValidation(event: any) {
    if (event.target.value) {
      this.addUserForm.get('city')?.setValidators([Validators.required]);
      this.addUserForm.get('state')?.setValidators([Validators.required]);
      this.addUserForm.get('zipCode')?.setValidators([Validators.required]);
      this.displayRequired = true;
    }
    else {
      this.addUserForm.get('city')?.setValidators(null);
      this.addUserForm.get('state')?.setValidators(null);
      this.addUserForm.get('zipCode')?.setValidators(null);
      this.displayRequired = false;
    }
    this.addUserForm.get('city')?.updateValueAndValidity({ emitEvent: false });
    this.addUserForm.get('state')?.updateValueAndValidity({ emitEvent: false });
    this.addUserForm.get('zipCode')?.updateValueAndValidity({ emitEvent: false });
  }

  onUserNameChange(event: any) {
    if (event.target.value) {
      this.addUserForm.patchValue({ email: event.target.value })
    }
  }
}
