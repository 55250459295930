<div class="modal-content">
  <div class="modal-body">
    <div class="add-pro-outer">
      <div class="add-hdr">
        <div class="heading">
          <h2>Add User Profile</h2>
        </div>
        <div class="add-act">
          <a href="javascript:void(0)" title="" (click)="cancel()" class="close" data-bs-dismiss="modal"
            aria-label="Close"><img src="assets/images/cross.svg" alt="" /></a>
        </div>
      </div>
      <div class="row" *ngIf="showError">
        <div class="col-12">
          <div class="alert alert-danger alert-dismissible fade show my-2 " role="alert">
            <ul class="view-error">
              <li *ngFor="let err of errorList">{{err}}</li>
            </ul>
            <button type="button" class="btn-close" (click)="closeError()" data-bs-dismiss="alert"
              aria-label="Close"></button>
          </div>
        </div>
      </div>
      <div class="add-content add-form">
        <div class="pdg-con">
          <form class="row g-3" method="post" [formGroup]="addUserForm">
            <div class="add-form">
              <div class="add-fld">
                <div class="add-title">
                  <h3>User Information</h3>
                </div>
                <div class="row">
                  <div class="row frmrow">
                    <div class="col-12 col-sm-12 col-md-6  mb-3  has-validation">
                      <label class="form-label">First Name<span class='star-color'> *</span></label>
                      <input formControlName="firstName" class="form-control rd-con" placeholder="First Name" />
                      <div class="display-error" *ngIf="f.firstName.invalid && submitted">
                        <div *ngIf="f.firstName.invalid && submitted">
                          <span *ngIf="f.firstName.errors?.required">
                            First Name is required.
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6  mb-3  has-validation">
                      <label class="form-label">Last Name<span class='star-color'> *</span></label>
                      <input formControlName="lastName" class="form-control rd-con" placeholder="Last Name" />
                      <div class="display-error" *ngIf="f.lastName.invalid && submitted">
                        <div *ngIf="f.lastName.invalid && submitted">
                          <span *ngIf="f.lastName.errors?.required">
                            Last Name is required.
                          </span>
                        </div>
                      </div>
                    </div>

                    <div class="col-12 col-sm-12 col-md-12  mb-3  has-validation">
                      <label class="form-label">User Name<span class='star-color'> *</span></label>
                      <input class="form-control rd-con" placeholder="User Name" formControlName="userEmail"
                        (change)="onUserNameChange($event)" />
                      <div class="display-error" *ngIf="f.userEmail.invalid && submitted">
                        <div *ngIf="f.userEmail.invalid && submitted">
                          <span *ngIf="f.userEmail.errors?.required">
                            User Name is required.
                          </span>
                        </div>
                      </div>
                    </div>

                    <div class="col-12 col-sm-12 col-md-12 mt-2">
                      <div class="des-con">
                        <div class="row">
                          <div class="col-12 col-sm-6 col-md-12">
                            <label class="form-label">
                              Address<span></span></label>
                            <input formControlName="street1" class="form-control" placeholder="Street 1"
                              (focusout)="removeSpaces($event,f.street1)" (change)="setCityStateZipValidation($event)"
                              [maxlength]="60" />
                            <div class="display-error">
                            </div>
                          </div>
                          <div class="col-12 col-sm-6 col-md-12 mt-3 mb-1">
                            <input formControlName="street2" class="form-control" placeholder="Street 2"
                              (focusout)="removeSpaces($event,f.street2)" [maxlength]="60" />
                          </div>
                          <div class="col-12 col-sm-6 col-md-4 mt-2">
                            <label class="form-label">City <span *ngIf="displayRequired" class='star-color'>
                                *</span></label>
                            <input formControlName="city" class="form-control" placeholder="City" />
                            <div class="display-error">
                              <div *ngIf="f.city.invalid && submitted">
                                <span *ngIf="f.city.errors?.required">
                                  {{_AddprospectScreen.city.required}}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div class="col-12 col-sm-6 col-md-5 mt-2">
                            <label class="form-label">State <span *ngIf="displayRequired" class='star-color'>
                                *</span></label>
                            <select class="select-cs form-select" formControlName="state">
                              <option value="" disabled selected>Select State
                              </option>
                              <option *ngFor="let state of statesList" value="{{state.stateCode}}">
                                {{state.stateName}}</option>
                            </select>
                            <div class="display-error">
                              <div *ngIf="f.state.invalid && submitted">
                                <span *ngIf="f.state.errors?.required">
                                  {{_AddprospectScreen.state.required}}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div class="col-12 col-sm-6 col-md-3 mb-1 mt-2">
                            <label class="form-label">ZIP Code <span *ngIf="displayRequired" class='star-color'>
                                *</span></label>
                            <input [dropSpecialCharacters]="false" (keypress)="ValidateKey.WholeNumbers($event,10)"
                              formControlName="zipCode" class="form-control" mask="00000-0000||00000"
                              placeholder="#####-####" (keyup)="setZipCode(f.zipCode.value)"
                              (focusout)="removeSpaces($event,f.zipCode)"
                              [class.errorInput]="(f.zipCode.invalid || inValidZip) && submitted" />

                            <div class="display-error" *ngIf="f.zipCode.invalid && submitted">
                              <div>
                                <span *ngIf="f.zipCode.errors?.required">
                                  {{_AddprospectScreen.zipCode.required}}
                                </span>
                                <span *ngIf="f.zipCode.errors?.maxlength || f.zipCode.errors?.maxlength">
                                  {{_AddprospectScreen.zipCode.length}}
                                </span>
                              </div>
                            </div>
                            <div class="display-error">
                              <div *ngIf="inValidZip && submitted">
                                <span>
                                  {{_AddprospectScreen.zipCode.length}}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-12 col-sm-12 col-md-6 mb-3 phone-div">
                      <div class="phone">
                        <label class="form-label">Phone Number (Office)<span class='star-color'> *</span></label>
                        <input formControlName="phoneNumber" class="form-control rd-con" placeholder="(###) ###-####"
                          mask="(000) 000-0000"
                          [class.errorInput]="(f.phoneNumber.invalid || phoneInvalid) && submitted" />
                        <div class="display-error" *ngIf="f.phoneNumber.invalid && submitted">
                          <span *ngIf="f.phoneNumber.errors?.mask">
                            {{_AddprospectScreen.phoneNumber.mask}}
                          </span>
                          <span *ngIf="f.phoneNumber.errors?.required">
                            {{_AddprospectScreen.phoneNumber.required}} </span>
                        </div>
                      </div>
                      <div class="ext ms-4">
                        <label class="form-label">Extension</label>
                        <input type="text" formControlName="extension" placeholder="#####" class="form-control rd-con"
                          (keypress)="ValidateKey.integer($event, 5)">
                      </div>
                    </div>

                    <div class="col-12 col-sm-12 col-md-6 mb-3">
                      <label class="form-label">Email <span class='star-color'> *</span></label>
                      <input class="form-control rd-con" formControlName="email" placeholder="example@email.com" />
                      <div class="display-error" *ngIf="f.email.invalid && submitted">
                        <div *ngIf="f.email.invalid && submitted">
                          <span *ngIf="f.email.errors?.pattern">
                            {{_AddprospectScreen.email.pattern}}
                          </span>
                          <span *ngIf="f.email.errors?.maxlength">
                            {{_AddprospectScreen.email.maxlength}}
                          </span>
                          <span *ngIf="f.email.errors?.required">
                            {{_AddprospectScreen.email.required1}}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div class="col-12 col-sm-12 col-md-6 mb-3 ">

                      <label class="form-label">Direct Contact <span class='star-color'></span></label>
                      <input class="form-control rd-con" placeholder="(###) ###-####" formControlName="directContact"
                        mask="(000) 000-0000" />
                      <div class="display-error" *ngIf="f.directContact.invalid && submitted">
                        <span *ngIf="f.directContact.errors?.mask">
                          {{_AddprospectScreen.phoneNumber.mask}}
                        </span>

                      </div>
                      <div class="display-error" *ngIf="phoneInvalid && submitted">
                        <span>
                          {{_AddprospectScreen.phoneNumber.required}}
                        </span>
                      </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6  mb-3  has-validation">
                      <label class="form-label">Branch<span class='star-color'> *</span></label>
                      <select class="form-select" aria-label="Default select example" formControlName="branchId">
                        <option value="" disabled selected>Select Branch </option>
                        <ng-container *ngFor="let branch of branchList">
                          <option value="{{branch.id}}">{{branch.displayName}}</option>
                        </ng-container>
                      </select>
                      <div class="display-error" *ngIf="f.branchId.invalid && submitted">
                        <div *ngIf="f.branchId.invalid && submitted">
                          <span *ngIf="f.branchId.errors?.required">
                            Branch is required.
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6  mb-3  has-validation">
                      <label class="form-label">User Role <span class='star-color'> *</span></label>
                      <p-multiSelect class="form-control form-control-mutileSelect user-Multiselect"
                        [options]="rolesList" defaultLabel="Select Values" optionLabel="roleName" optionValue="id"
                        formControlName="userRoleId" selectedItemsLabel="{0} items selected">
                      </p-multiSelect>
                      <div class="display-error" *ngIf="f.userRoleId.invalid && submitted">
                        <div *ngIf="f.userRoleId.invalid && submitted">
                          <span *ngIf="f.userRoleId.errors?.required">
                            User Role is required.
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6  mb-3  has-validation">
                      <label class="form-label">Job Title<span class='star-color'> *</span></label>
                      <input formControlName="jobTitle" class="form-control rd-con" placeholder="Job Title" />
                      <div class="display-error" *ngIf="f.jobTitle.invalid && submitted">
                        <div *ngIf="f.jobTitle.invalid && submitted">
                          <span *ngIf="f.jobTitle.errors?.required">
                            Job Title is required.
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 mb-3  has-validation">
                      <label class="form-label">Manager<span class='star-color'></span></label>
                      <div class="flex-div auto-comp-wd userm add-user-role">
                        <p-autoComplete placeholder="Manager" class="auto-comp-view form-control"
                          [showEmptyMessage]="true" [suggestions]="accountManagerlist" field="value" [minLength]="1"
                          (onSelect)="selectItem($event.value,'managerId')"
                          (onDropdownClick)="getCompanyList('accountManager',$event)"
                          (completeMethod)="getCompanyList('accountManager',$event)" formControlName="managerId">
                          <ng-template let-account pTemplate="item">
                            <div class="company-item">
                              <div>{{ account.userName }}</div>
                            </div>
                          </ng-template>
                        </p-autoComplete>
                        <img src="assets/images/search.svg" (click)="openLookupModal('AccountManager')" alt=""
                          class="ms-2" />
                      </div>
                    </div>

                    <div class="col-12 col-sm-12 col-md-6 mb-3  has-validation">
                      <label class="form-label">Business Unit<span class='star-color'></span></label>
                      <input class="form-control rd-con" placeholder="Business Unit" formControlName="businessUnit" />
                    </div>
                    <div class="col-12 col-sm-12 col-md-12  mb-3  has-validation">
                      <label class="form-label">Access Level<span class='star-color'></span></label>
                      <select class="form-select" aria-label="Default select example" formControlName="accessLevel">
                        <option value="" disabled selected>Select Access Level</option>
                        <ng-container *ngFor="let level of userAccessLevelList">
                          <option value="{{level.userAccessLevelValue}}">{{level.userAccessLevelName}}</option>
                        </ng-container>
                      </select>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6  mb-3  has-validation">
                      <label class="form-label">EPIC Lookup Code<span class='star-color'></span></label>
                      <input class="form-control" placeholder="EPIC Lookup Code" formControlName="epicLookupCode" />
                    </div>
                    <div class="col-12 col-sm-12 col-md-6  mb-3 has-validation">
                      <label class="form-label">Azure Object Id<span class='star-color'> *</span></label>
                      <input formControlName="oid" class="form-control rd-con" placeholder="Azure Object Id" />
                      <div class="display-error" *ngIf="f.oid.invalid && submitted">
                        <span *ngIf="f.oid.errors?.required">
                          Azure Object Id is required.
                        </span>
                      </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6  mb-3  has-validation">
                      <label class="form-label">Producer Level<span class='star-color'></span></label>
                      <select class="form-select" aria-label="Default select example" formControlName="producerLevel"
                        (change)="onProducerLevelChange($event)">
                        <option value="" selected>Select Producer Level</option>
                        <ng-container *ngFor="let level of producerLevelList">
                          <option value="{{level.producerLevelId}}">{{level.producerLevelName}}</option>
                        </ng-container>
                      </select>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6  mb-3 date-control">
                      <label for="producerLevelDate" class="form-label mb-3">Producer Level Assigned/Updated on</label>
                      <p-calendar [readonlyInput]="true" class="form-control" placeholder="MM/DD/YYYY" [showIcon]="true" [iconDisplay]="'input'"
                        (onSelect)="changeDate($event)" dateFormat="mm/dd/yy" inputId="producerLevelDate"
                        [ngClass]="{error:f.producerLevelDate.invalid}"
                        formControlName="producerLevelDate"></p-calendar>
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 mb-3 has-validation">
                      <label class="form-label">Service Description </label>
                      <textarea class="form-control" id="rmgDescription" formControlName="serviceDescription" rows="3"
                        placeholder="Service Description"></textarea>
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 my-3">
                      <div class="add-fld">
                        <div class="add-title">
                          <h3>Upload Photo</h3>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12 col-sm-12 col-md-12 my-3">
                          <div class="content-block ">
                            <div class="upload-block file-upload-wrapper">
                              <div class="file-drop-area">
                                <span class="flex-div justify-center upl"><img src="assets/images/upload-file.svg"
                                    width="30px" alt="" /></span>
                                <span class="file-message small">Click or drag & drop your data file.</span>
                                <input id="file" class="file-input" #fileUpload type="file"
                                  (change)="onFileChange($event)" accept=".png, .jpg, .jpeg" />
                              </div>
                            </div>
                            <div style="text-align: left">
                              <span *ngIf="filesToUpload">
                                Import file name : {{ filesToUpload.name }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="blk-bt">
          <button class="btn btn-outline-primary  me-3" (click)="cancel()">Cancel</button>
          <button class="btn btn-primary" (click)="updateUserProfile()">Save</button>
        </div>
      </div>

      <!-- Dialog for confirmation -->
      <p-dialog [header]="_confirmBox.header" [modal]="true" [(visible)]="_confirmBox.isVisible"
        [breakpoints]="{'960px': '75vw'}" [style]="{width: '40vw'}" [draggable]="false" [resizable]="false"
        [styleClass]="_confirmBox.borderColor" *ngIf="_confirmBox">
        <p>{{_confirmBox.message}}</p>
        <ng-template pTemplate="footer" *ngIf="_confirmBox.hasFooter">
          <p-button (click)="_confirmBox.isVisible = false" label="No" styleClass="btn btn-outline-primary"></p-button>
          <p-button (click)="confirmBoxCancel()" label="Yes" styleClass="btn btn-primary"></p-button>
        </ng-template>
      </p-dialog>

      <!-- Dialog for error -->
      <p-dialog [header]="_errorBox.header" [modal]="true" [(visible)]="_errorBox.isVisible"
        [breakpoints]="{'960px': '75vw'}" [style]="{width: '40vw'}" [draggable]="false" [resizable]="false"
        [styleClass]="_errorBox.borderColor" *ngIf="_errorBox">
        <p>{{_errorBox.message}}</p>
        <ng-template pTemplate="footer">
          <p-button (click)="errorBoxCancel()" label="OK" styleClass="btn btn-primary"></p-button>
        </ng-template>
      </p-dialog>

    </div>
  </div>
</div>
