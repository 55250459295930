import { Component, OnInit } from '@angular/core';
import { NavigationPath } from 'src/app/shared/class/navigation-path';
import { LoaderService } from 'src/app/shared/utility/loader/loader.service';
import { UserManagementService } from '../../services/user-management.service';

@Component({
  selector: 'app-user-role-permission',
  templateUrl: './user-role-permission.component.html',
  styleUrls: ['./user-role-permission.component.css'],
})
export class UserRolePermissionComponent implements OnInit {
  _navigationPath: NavigationPath = new NavigationPath();
  rolesList: any = [];
  rolesListLocal: any = [];
  permissionList: any = [];
  permissionId: any = [];
  rollId: any = [];
  showError: boolean = false;
  errorMessage: string = '';
  errorList: any = [];
  userId: any;
  userDetails: any;
  isChecked: boolean = false;
  isDisabled: boolean = false;
  count = 0;
  disableCheck: any;

  constructor(
    private _loderService: LoaderService,
    private _userManagementService: UserManagementService
  ) { }

  ngOnInit(): void {
    this._userManagementService.notifyData.subscribe((resp: any) => {
      this.userId = resp;
    });
    this.getUserRole();
    this.getUserDetails(this.userId);
  }

  //get user role method
  getUserRole() {
    this._loderService.show();
    this._userManagementService.getRoleList().subscribe(
      (resp: any) => {
        if (resp) {
          this._loderService.hide();
          this.rolesListLocal = resp.rolesList;
        }
      },
      (error: any) => {
        this._loderService.hide();
      }
    );
  }

  getPermission() {
    // this._loderService.show()
    // this._userManagementService.getPermissionList().subscribe((resp : any)=>{
    //   if(resp){
    //     this._loderService.hide();
    //     this.permissionList = resp.permissionList;
    //   }
    // },
    // ((error : any) =>{
    //   this._loderService.hide();
    // }))
    //  let data =  this._userManagementService.getPermissionList()
    //   this.permissionList =  data.permissionList
  }

  //get permission id method
  getPermissionId(perId: any) {
    this.permissionId.push(perId);
  }

  //get role id method
  getRollId(rollId: any, event: any) {
    this.showError = false;
    if (event.target.checked == true) {
      if (this.rollId.length >= 0) {
        this.rollId.push({ id: rollId });
      }
      if (this.rollId.length > 2) {
        this.showError = true;
        this.errorMessage = 'You can select only 1 rules.';
        this.errorList = [];
        this.errorList.push(this.errorMessage);
        return;
      }
    } else {
      if (event.target.checked == false) {
        if (this.rollId.length <= 3) {
          this.showError = false;
          for (var i = 0; i < this.rollId.length; i++) {
            if (this.rollId[i].id == rollId) {
              this.rollId.splice(i, 1);
            }
          }
        }
        else {
          for (var i = 0; i < this.rollId.length; i++) {
            if (this.rollId[i].id == rollId) {
              this.rollId.splice(i, 1);
            }
          }
          this.showError = true;
          this.errorMessage = 'You can select only 1 rules.';
          this.errorList = [];
          this.errorList.push(this.errorMessage);
          return;
        }
      }
      if (this.rollId.length > 2) {
        this.showError = true;
      }
      else {
        this.showError = false;
      }
    }
  }

  //save roles method
  saveRoles() {
    let data = {
      roles: this.rollId,
      userId: this.userId
    };
    if (data) {
      this._loderService.show();
      this._userManagementService.craeteRoles(data).subscribe(
        (resp: any) => {
          if (resp) {
            this._loderService.hide();
          }
        },
        (error: any) => {
          this.showError = true;
          this.errorMessage =
            'Error : ' + error.message + ', Status: ' + error.status;
          this.errorList = [];
          this.errorList.push(this.errorMessage);
          this._loderService.hide();
        }
      );
    }
  }
  //close error
  closeError() {
    this.showError = false;
  }

  //get user detail method
  getUserDetails(id: any) {
    if (id) {
      this._userManagementService.getUserById(id).subscribe((resp: any) => {
        this.userDetails = resp;
        this.rolesListLocal.forEach((role: any) => {
          let checked = false;
          this.userDetails.user.roles.forEach((item: any) => {
            if (item.id == role.id) {
              this.count = this.count + 1;
              this.rollId.push({ id: role.id });
              checked = true;
            }
          });
          role['checked'] = checked;
          this.rolesList.push(role);
        });
        this.rolesList.forEach((x: any, index: any) => {
          if (index == 7) {
            this.disableCheck = index;
          }
        });
      });
    }
  }
}
