<div class="main-body-con">
  <div class="page-container pt-4">
    <div class="page-hdr-outer ps-4 pe-4">
      <div class="page-hdr">
        <h2>User Management</h2>
      </div>
      <div class="search-panel mb-3">
        <div class="row">
          <div class="col-12 col-sm-12 col-md-7">
            <div class="row">
              <div class="col-12 col-sm-12 col-md-7">
                <p-dropdown >
                  <ng-template let-group pTemplate="group">
                    <div class="flex align-items-center">
                      <span>Filter by Role</span>
                    </div>
                  </ng-template>
                </p-dropdown>

              </div>
            </div>
          </div>
          <!-- <div class="col-12 col-sm-12 col-md-5">
            <div class="nyc-search1">
              <p-dropdown [options]="searchTypeList" [(ngModel)]="selectedSearchType" [editable]="false"
                optionLabel="name" placeholder="Search"></p-dropdown>
              <input type="search" class="srch-input" [(ngModel)]="searchText" (search)="filterSearch()"
                (focusout)="filterSearch()" />
              <input type="submit" class="btn btn-sbt" value="" (click)="filterSearch()" />
            </div>
          </div> -->
        </div>
      </div>



      <div class="row p-4">
        <div class="col-md-3">
            <a href="javascript:void(0)"
            [routerLink]="['granted']" title="Permission Granted">
           Permission Granted</a>
        </div>
        <div class="col-md-3">
            <a href="javascript:void(0)"
            [routerLink]="['denied']" title="Permission Denied">
           Permission Denied</a>
        </div>

        <div class="col-md-3">
          <a href="javascript:void(0)"
          [routerLink]="['user-role']" title="user role">
         Users</a>
      </div>
      </div>

      <div class="row">
        <div class="col-md-1">Role :</div>
        <div class="col-md-3">
          <select class="select-cs form-select">
            <option value="" disabled selected>Select Role</option>
            <option *ngFor="let role of rolesList" value="{{ role.id }}">
              {{ role.roleName }}
            </option>
          </select>
        </div>
      </div>

      <div class="mt-3">
        <h2>Permission</h2>
        <ng-container *ngFor="let permision of permissionList">
        <div class="pt-3 row">
            <div class="col-md-2">{{permision.permissionDisplayName}}</div>
            <div class="col-md-1">
              <input type="checkbox" (click)="getPermissionId(permision.permissionId)" class="form-check-input" value="{{permision.permissionId}}"/>
            </div>
        </div>
    </ng-container>
      </div>
    </div>
    <div class="col-12 col-sm-12 col-md-12 pt-3 mt-4">
        <button class="btn btn-primary me-4">
          Save
        </button>
      </div>
  </div>
</div>
