import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoaderService } from 'src/app/shared/utility/loader/loader.service';
import { UserManagementService } from '../../services/user-management.service';

@Component({
  selector: 'app-user-access',
  templateUrl: './user-access.component.html',
  styleUrls: ['./user-access.component.css'],
})
export class UserAccessComponent implements OnInit {
  userList: any = [];
  accessList: any = [];
  accessLevelList: any = [
    {
      accessLevelValue: 'Level2',
      accessLevelDisplayName: 'CE',
    },
    {
      accessLevelValue: 'Level1',
      accessLevelDisplayName: 'CE-New',
    },
    {
      accessLevelValue: 'Level3',
      accessLevelDisplayName: 'SCE',
    },
  ];
  userId: any;
  accessLevelValue: any;
  isValid: boolean = true;
  constructor(
    private router: Router,
    private _loderService: LoaderService,
    private _userManagementService: UserManagementService
  ) {}

  ngOnInit(): void {
    this.getUserList();
    this.getAccessList();
  }

  //get user list method
  getUserList() {
    this._loderService.show();
    this._userManagementService.getUsersList().subscribe(
      (resp) => {
        if (resp) {
          this._loderService.hide();
          this.userList = resp.userList;
        }
      },
      (error) => {
        this._loderService.hide();
      }
    );
  }

  // get access list
  getAccessList() {
    // this._userManagementService.getAccessLavelList().subscribe((resp)=>{
    //   this.accessList = resp;
    // })
    this.accessList = this.accessLevelList;
  }
  //get user detail method
  getUsersDetail(data: any) {
    if (data) {
      this.userId = data;
    }
  }

  //get user access detail method
  getUsers(data: any) {
    if (data) {
      this.accessLevelValue = data;
      this.isValid = false;
    }
  }

  // save the details
  save() {
    let data = {
      userId: this.userId,
      accessLevelValue: this.accessLevelValue,
    };
    if (data) {
      this._userManagementService.createAccess(data).subscribe((resp) => {});
    }
  }
}
