import { Component, OnInit } from '@angular/core';
import { NavigationPath } from 'src/app/shared/class/navigation-path';
import { LoaderService } from 'src/app/shared/utility/loader/loader.service';
import { UserManagementService } from '../../services/user-management.service';

@Component({
  selector: 'app-user-permision',
  templateUrl: './user-permision.component.html',
  styleUrls: ['./user-permision.component.css']
})
export class UserPermisionComponent implements OnInit {
  _navigationPath: NavigationPath = new NavigationPath();
  rolesList: any = [];
  permissionList: any = [];
  constructor(
    private _loderService: LoaderService,
    private _userManagementService: UserManagementService
  ) { }

  ngOnInit(): void {
    this.getRoleList();
    this.getPermission();
  }


  getRoleList() {
    this._loderService.show()
    this._userManagementService.getRoleList().subscribe(resp => {
      if (resp) {
        this._loderService.hide();
        this.rolesList = resp.rolesList;
      }
    },
      (error => {
        this._loderService.hide();
      }))
    // let data = this._userManagementService.getRoleList()
    // this.rolesList = data.roleList;
  }

  getPermission() {
    this._loderService.show()
    this._userManagementService.getPermissionList().subscribe((resp: any) => {
      if (resp) {
        this._loderService.hide();
        this.permissionList = resp.permissionList;
      }
    },
      ((error: any) => {
        this._loderService.hide();
      }))

    //  let data =  this._userManagementService.getPermissionList()
    //   this.permissionList =  data.permissionList
  }
  getPermissionId(perId: any) {
    console.log(" permission id ", perId);
  }


  
}
