import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UserManagementRoutingModule } from './user-management-routing.module';
import { UserPermisionComponent } from './components/user-permision/user-permision.component';
import { UserPermisionGrantedComponent } from './components/user-permision-granted/user-permision-granted.component';
import { UserPermisionDeniedComponent } from './components/user-permision-denied/user-permision-denied.component';
import { UserRoleComponent } from './components/user-role/user-role.component';
import { UserRolePermissionComponent } from './components/user-role-permission/user-role-permission.component';
import { UserAccessComponent } from './components/user-access/user-access.component';
import { DropdownModule } from 'primeng/dropdown';
import { PaginatorModule } from 'primeng/paginator';
// import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { FilterPipe } from './pipes/filter.pipe';
import { UniquePipe } from './pipes/unique.pipe';
import { UserRoleEditComponent } from './components/user-role-edit/user-role-edit.component';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { ReactiveFormsModule } from '@angular/forms';
import { RoleManagementComponent } from './components/role-management/role-management.component';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { UserRoleAddComponent } from './components/user-role-add/user-role-add.component';
import { MultiSelectModule } from 'primeng/multiselect';
import { GridModule } from '@progress/kendo-angular-grid';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { DropDownsModule } from "@progress/kendo-angular-dropdowns";
import { ButtonModule as BtnModeule } from '@progress/kendo-angular-buttons';
import { SchedulerModule } from '@progress/kendo-angular-scheduler';
import { CalendarModule } from 'primeng/calendar';
import { SharedModule } from 'src/app/shared/shared.module';
import {NgxMaskDirective, NgxMaskPipe} from "ngx-mask";

@NgModule({
  declarations: [
    UserPermisionComponent,
    UserPermisionGrantedComponent,
    UserPermisionDeniedComponent,
    UserRoleComponent,
    UserRolePermissionComponent,
    UserAccessComponent,
    FilterPipe,
    UniquePipe,
    UserRoleEditComponent,
    RoleManagementComponent,
    UserRoleAddComponent
  ],
  imports: [
    CommonModule,
    UserManagementRoutingModule,
    DropdownModule,
    PaginatorModule,
    // Ng2SearchPipeModule,
    DialogModule,
    ButtonModule,
    ReactiveFormsModule,
    AutoCompleteModule,
    MultiSelectModule,
    GridModule,
    SharedModule,
    InputsModule,
    DropDownsModule,
    BtnModeule,
    SchedulerModule,
    CalendarModule,
    NgxMaskPipe,
    NgxMaskDirective
  ]
})
export class UserManagementModule { }
