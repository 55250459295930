import { Component, OnInit } from '@angular/core';
import { NavigationPath } from 'src/app/shared/class/navigation-path';
import { LoaderService } from 'src/app/shared/utility/loader/loader.service';
import { UserManagementService } from '../../services/user-management.service';

@Component({
  selector: 'app-user-permision-granted',
  templateUrl: './user-permision-granted.component.html',
  styleUrls: ['./user-permision-granted.component.css'],
})
export class UserPermisionGrantedComponent implements OnInit {
  _navigationPath: NavigationPath = new NavigationPath();
  rolesList: any = [];
  permissionId: any = [];
  permissionList: any = [];
  showError: boolean = false;
  errorMessage: string = '';
  errorList: any = [];
  userId: any;
  userDetails: any;
  permissionGrantedLocal: any = [];

  constructor(
    private _loderService: LoaderService,
    private _userManagementService: UserManagementService
  ) { }

  ngOnInit(): void {
    this._userManagementService.notifyData.subscribe((resp) => {
      this.userId = resp;
    });
    this.getUserRole();
    this.getPermission();
    this.getUserDetails(this.userId);
  }

  getUserRole() {
    // this._loderService.show()
    // this._userManagementService.getRoleList().subscribe(resp=>{
    //   if(resp){
    //     this._loderService.hide();
    //     this.rolesList = resp.roleList;
    //   }
    // },
    // (error =>{
    //   this._loderService.hide();
    // }))
    // let data = this._userManagementService.getRoleList()
    // this.rolesList = data.roleList;
  }

  //get permission list
  getPermission() {
    this._loderService.show();
    this._userManagementService.getPermissionList().subscribe(
      (resp: any) => {
        if (resp) {
          this._loderService.hide();
          this.permissionGrantedLocal = resp.permissionList;
        }
      },
      (error: any) => {
        this._loderService.hide();
      }
    );
  }

  //get permission id method
  getPermissionId(perId: any, event: any) {
    if (event.target.checked == true) {
      this.permissionId.push(perId);
    } else {
      let index = this.permissionId.indexOf(perId);
      this.permissionId.splice(index, 1);
    }
  }

  //save permission method
  savePermission() {
    let data = {
      userId: this.userId,
      permissionId: this.permissionId,
    };
    if (data) {
      this._loderService.show();
      this._userManagementService.craetePermission(data).subscribe(
        (resp) => {
          if (resp) {
            this._loderService.hide();
          }
        },
        (error) => {
          this.showError = true;
          this.errorMessage =
            'Error : ' + error.message + ', Status: ' + error.status;
          this.errorList = [];
          this.errorList.push(this.errorMessage);
          this._loderService.hide();
        }
      );
    }
  }
  closeError() {
    this.showError = false;
  }

  //get user detail method
  getUserDetails(id: any) {
    if (id) {
      this._userManagementService.getUserById(id).subscribe((resp) => {
        this.userDetails = resp;
        this.permissionGrantedLocal.forEach((role: any) => {
          let checked = false;
          this.userDetails.user.permissionsGranted.forEach((val: any) => {
            if (role.permissionId == val.permissionId) {
              this.permissionId.push(val.permissionId);
              checked = true;
            }
          });
          role['checked'] = checked;
          this.permissionList.push(role);
        });
      });
    }
  }
}
