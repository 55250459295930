import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationPath } from 'src/app/shared/class/navigation-path';
import { UserManagementService } from '../../services/user-management.service';

@Component({
  selector: 'app-role-management',
  templateUrl: './role-management.component.html',
  styleUrls: ['./role-management.component.css']
})
export class RoleManagementComponent implements OnInit {
  _navigationPath: NavigationPath;
  rolesList: any = [];
  permissionList: any = [];
  curUser: any;
  roleListById: any = [];
  permissionIdList: any = [];
  permissionIdList1: any = []
  roleId: any;
  errorList: any = [];
  showError: boolean = false;
  errorMessage: string = '';
  result: any[] = [];
  permissionGrantedLocal: any = [];
  rolebygroupId: any = [];
  constructor(
    // private _loderService: LoaderService,
    private _AcmeProductService: UserManagementService,
    private _router: Router
  ) {
    this._navigationPath = new NavigationPath();
  }

  ngOnInit(): void {
    this.getRoleList();
    this.getPermission();
  }

  //get role list
  getRoleList() {
    // this._loderService.show();
    this._AcmeProductService.getRoleList().subscribe(
      (resp) => {
        if (resp) {
          // this._loderService.hide();
          this.rolesList = resp.rolesList;
        }
      },
      (error) => {
        //this._loderService.hide();
      }
    );
  }

  //get permission list
  getPermission() {
    // this._loderService.show();
    this._AcmeProductService.getRolePermissionList().subscribe(
      (resp: any) => {
        if (resp) {
          // this._loderService.hide();
          this.permissionGrantedLocal = resp.permissionGroupList;
          this.group()
        }
      },
      (error: any) => {
        // this._loderService.hide();
      }
    );
  }

  //group the permissions
  group() {
    var groups = new Set(this.permissionGrantedLocal.map((item: any) => (item.module)))
    this.result = [];
    groups.forEach((g: any) =>

      this.result.push({
        module
          : g,
        values: this.permissionGrantedLocal.filter((i: any) => i.module === g)
      }
      ))

  }

  // getRolePermission(rolePermissionId: any) {
  //
  //   this.permissionList = [];
  //   this.permissionIdList = [];
  //   if (rolePermissionId) {
  //     this.roleId = rolePermissionId;
  //     this._AcmeProductService
  //       .getRolepermission(rolePermissionId)
  //       .subscribe((resp) => {
  //         if (resp) {
  //           this.roleListById = resp.permissionList;
  //           this.permissionGrantedLocal.forEach((role: any) => {
  //             let checked = false;
  //             this.roleListById.forEach((val: any) => {
  //               if (role.permissionId == val.permissionId) {
  //                 this.permissionIdList.push({
  //                   permissionId: val.permissionId,
  //                 });
  //                 checked = true;
  //               }
  //             });
  //             role['checked'] = checked;
  //             this.permissionList.push(role);
  //           });
  //         }
  //       });
  //   }
  // }

  //get permission according to id
  getRolePermission(rolePermissionId: any) {
    this.permissionList = [];
    this.permissionIdList1 = [];
    if (rolePermissionId) {
      this.roleId = rolePermissionId;
      this._AcmeProductService
        .getRolepermission(rolePermissionId)
        .subscribe((resp) => {
          if (resp) {
            this.roleListById = resp.role.rolePermissions;
            // this.roleListById.forEach((permissionGroupId: any) => {
            //   this.rolebygroupId = permissionGroupId
            //   console.log("groupId", this.rolebygroupId)
            // })
            console.log("this.rolebyid", this.roleListById) //outer
            this.permissionGrantedLocal.forEach((role: any) => {
              let checked = false;
              this.roleListById.forEach((val: any) => {
                if (role.id == val.permissionGroupId) {
                  // let duplicateId = this.permissionIdList.find((x: any) => x.permissionId == val.permissionGroupId)
                  // console.log("duplicate", duplicateId)
                  if (!this.permissionIdList.find((x: any) => x == val.permissionGroupId)) {
                    this.result.forEach((x: any) => {
                      x.values.forEach((y: any) => {
                        if (y.id == role.id) {
                          y.checked = true;
                        }
                      });

                    });
                    console.log("after foreach", this.result)
                    this.permissionIdList.push(
                      val.permissionGroupId,
                    );
                  }
                  // this.permissionIdList.forEach((val1: any) => {
                  //   if (val1.permissionId == val.permissionGroupId) {
                  //     this.permissionIdList.push({
                  //       permissionId: val.permissionGroupId,

                  //     });

                  //   }

                  // });


                  checked = true;

                }


              });
              // role['checked'] = checked;
              // this.permissionList.push(role);
            });
            console.log(" this.permissionIdList", this.permissionIdList)

          }
        });
    }
  }

  //get permission id
  getPermissionId(perId: any, event: any) {
    if (event.target.checked == true) {
      this.permissionIdList.push(perId);
    } else {
      let index = this.permissionIdList.indexOf(perId);
      this.permissionIdList.splice(index, 1);
    }
  }

  //save role permission method
  saveRolePermission() {
    let data = {
      roleAndPermissionGroupMapping: {
        "roleId": this.roleId,
        "permissionGroupIds": this.permissionIdList,
      }
    };
    console.log(" data ", data);

    // this._AcmeProductService.rolePermission(data).subscribe((res) => {
    //   if (res) {
    //     // this._router.navigateByUrl('/' + this._navigationPath.getsystemUrl());
    //   }

    // },
    //   (err: any) => {
    //     this.showError = true;
    //     if (err.status !== 200) {
    //       if (err.status === 400) {
    //         this.showError = true;
    //         let obj = err.error.errors;
    //         var arr = Object.keys(obj).map((key) => ({
    //           type: key,
    //           value: obj[key],
    //         }));
    //         this.errorList = [];
    //         this.errorMessage = arr[0].value[0];
    //         this.errorList.push(this.errorMessage);
    //       } else {

    //         this.showError = true;
    //         this.errorMessage = "Error : " + err.message + ", Status: " + err.status;
    //         this.errorList.push(this.errorMessage);
    //       }
    //     }
    //   });
  }

  //navigate to system manager
  navigateToUser() {
    // this._router.navigateByUrl('/' + this._navigationPath.getsystemUrl());
  }

  //close error method
  closeError() {
    this.showError = false;
  }
}
