import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NavigationPath } from 'src/app/shared/class/navigation-path';
import { ProspectListComponent } from '../prospect/components/prospect-list/prospect-list.component';
import { RoleManagementComponent } from './components/role-management/role-management.component';
import { UserAccessComponent } from './components/user-access/user-access.component';
import { UserPermisionDeniedComponent } from './components/user-permision-denied/user-permision-denied.component';
import { UserPermisionGrantedComponent } from './components/user-permision-granted/user-permision-granted.component';
import { UserPermisionComponent } from './components/user-permision/user-permision.component';
import { UserRolePermissionComponent } from './components/user-role-permission/user-role-permission.component';
import { UserRoleComponent } from './components/user-role/user-role.component';

const routes: Routes = [
  { path: '', component: UserRoleComponent },
  { path: NavigationPath.userPermission, component: UserPermisionComponent },
  { path: 'granted', component: UserPermisionGrantedComponent },
  { path: 'denied', component: UserPermisionDeniedComponent },
  { path: 'user-role', component: UserRoleComponent },
  { path: 'user-role-permission', component: UserRolePermissionComponent },
  { path: 'user-access', component: UserAccessComponent },
  { path: 'role-management', component: RoleManagementComponent },

  // { path: NavigationPath.UserRolePermission,component:UserRolePermissionComponent}

  //  { path:'user-role-permission',
  //     component: UserRolePermissionComponent,
  //     children: [
  //       {
  //         path:'granted', component: UserPermisionGrantedComponent
  //       },
  //       {
  //         path:'denied', component: UserPermisionDeniedComponent
  //       }
  //     ]
  //   }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserManagementRoutingModule { }
