<div class="main-body-con">
    <div class="page-container pt-4">
      <div class="page-hdr-outer ps-4 pe-4">
        <div class="page-hdr">
          <h2>User: {{userDetails?.user?.given_name}}</h2>
        </div>
        <div class="row" *ngIf="showError">
          <div class="col-12">
            <div class="alert alert-danger alert-dismissible fade show my-2 " role="alert">
              <ul class="view-error">
                <li *ngFor="let err of errorList">{{err}}</li>
              </ul>
              <button type="button" class="btn-close" (click)="closeError()" data-bs-dismiss="alert"
                aria-label="Close"></button>
            </div>
          </div>
        </div>
        <!-- <div class="row p-4">
          <div class="col-md-3">
              <a href="javascript:void(0)"
              [routerLink]="['/user-permission/granted']" title="Permission Granted">
             Permission Granted</a>
          </div>
          <div class="col-md-3">
              <a href="javascript:void(0)"
              [routerLink]="['/user-permission']" title="User Permission Management">
            User Permission Management</a>
          </div>
        </div> -->
  
        <!-- <div class="row">
          <div class="col-md-1">User :</div>
          <div class="col-md-3">
            <select class="select-cs form-select">
              <option value="" disabled selected>Select User</option>
              <option *ngFor="let role of rolesList" value="{{ role.id }}">
                {{ role.roleName }}
              </option>
            </select>
          </div>
        </div> -->
  
        <div class="mt-3">
          <h2>Permissions Denied</h2>
          <ng-container *ngFor="let permision of permissionList">
            <div class="pt-3 row">
                <div class="col-md-2">{{permision.permissionDisplayName}}</div>
                <div class="col-md-1">
                  <input type="checkbox" [checked]="permision.checked" (click)="getPermissionId(permision.permissionId,$event)" class="form-check-input" value="{{permision.permissionId}}"/>
                </div>
            </div>
        </ng-container>
        </div>
      </div>
      <div class="col-12 col-sm-12 col-md-12 pt-3 mt-4">
          <button class="btn btn-primary me-4" (click)="savePermission()">
            Save
          </button>
        </div>
    </div>
  </div>
